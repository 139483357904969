
<app-cabecera
  [seccion]="'about'">
</app-cabecera>
<app-minicabecera
  [titulo]=" 'About me' "
  [seccion]="'about'">
</app-minicabecera>
  
<main>
    <section class="slider__myself">
        <div class="slider__img">
          <img src="/assets/img/Me2.jpg" alt="">
        </div>
        <div class="slider__text">
          <p class="slider__text_p">I am a frontend developer with knowledge in the most used tools. I have experience in working for both individual and corporate clients. I consider myself a professional person, patient and eager to learn. My goal is to help you to create the website that best suits your needs.</p>
        </div>
    </section>
</main>